import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpServiceAbstract } from './http-service-abstract.service';
import { HttpClient } from '@angular/common/http';
import { ResultPaginated, ResponseSuccessPaginated, ResponseSuccess } from './response-success';
import { IModel } from './model-interface';

@Injectable()
export abstract class ServiceAbstract<T extends IModel> extends HttpServiceAbstract {

  constructor(public http: HttpClient) {
    super(http);
  }

  getAll(): Observable<T[]> {
    return this.http.get<ResponseSuccess<T[]>>(this.getUri(), this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
  }

  getPaginated(page: number = 1, pageSize: number): Observable<ResultPaginated<T>> {
    return this.http.get<ResponseSuccessPaginated<T>>(this.getUri() + `?page=${page}&pageSize=${pageSize}`, this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
  }

  getById(t: T): Observable<T> {
    return this.http.get<ResponseSuccess<T>>(this.getUri(t.id), this.getOptions())
      .map((res) => res.data)
      .catch(error => of())
  }

  add(t: T): Observable<T> {
    return this.http.post<ResponseSuccess<T>>(this.getUri(), JSON.stringify(t), this.getOptions())
      .map((result) => t)
      .catch(error => of())
  }

  update(t: T): Observable<T> {
    return this.http.put(this.getUri(t.id), JSON.stringify(t), this.getOptions())
      .map((res) => t)
      .catch(error => of())
  }

  remove(t: T): Observable<T> {
    return this.http.delete(this.getUri(t.id), this.getOptions())
      .map((result) => t)
      .catch(error => of())
  }

  getUri = (id?: string): string => `${this.getEnvironment().appApi.baseUrl}/${this.getEndpoint()}${(id ? '/' + id : '')}`;

}
