import { HttpParams } from '@angular/common/http';

export interface ICustomHttpParams {
  showSpinner : boolean
};

export class CustomHttpParams extends HttpParams {
  constructor(public param? : ICustomHttpParams) {
    super();
  }
}
