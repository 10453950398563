import { ResponseSuccess } from './response-success';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { CustomHttpParams, ICustomHttpParams } from './custom-http-params';

export interface OptionsHttp {
  headers?: HttpHeaders,
  params?: CustomHttpParams
}

@Injectable()
export abstract class HttpServiceAbstract {

  private options : OptionsHttp;

  constructor(public http: HttpClient) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'access-control-allow-origin': '*'
    });

    this.options = {
      headers : headers,
      params : new CustomHttpParams({ showSpinner : true })
    };
  }

  handleError = (response: Response | any): ErrorObservable<any>   => {
    console.log("console.log service-abstract error", response);
    return null;
  };

  mapper<TData>(response: ResponseSuccess<TData>): TData {
    return response.data;
  }

  abstract getEndpoint() : string;

  abstract getEnvironment(): any;

  protected getOptions = (): object => this.options;

  protected getOptionsWithoutSpinner = (): object => {
    this.options.params = new CustomHttpParams({ showSpinner : false });
    return this.options;
  }
}
