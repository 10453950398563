import { NgModule } from '@angular/core';
import { AuthenticationComponent } from './authentication.component';

@NgModule({
  imports: [
  ],
  declarations: [AuthenticationComponent],
  exports: [AuthenticationComponent]
})
export class AuthenticationModule { }
