import { NgModule } from '@angular/core';
import { ArchitectureComponent } from './architecture.component';

@NgModule({
  imports: [
  ],
  declarations: [ArchitectureComponent],
  exports: [ArchitectureComponent]
})
export class ArchitectureModule { }
