import { OAuth2Client } from './models/oauth2-client.interface';
import { Router } from '@angular/router';
import { Role } from './models/role.model';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { Token } from './models/token.model';
import { UserAuthenticated } from './models/user-authenticated.model';
import { RefreshToken } from './models/refresh-token.model';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { HttpServiceAbstract, ResponseSuccess } from '@senac/architecture';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthorizationCode } from './models/authorization-code.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends HttpServiceAbstract {

  jwtHelper = new JwtHelperService();

  constructor(http: HttpClient,
    private router: Router) {
    super(http);
  }

  public hasRole<TRoleEnum>(role: TRoleEnum) {
    const user = this.getStaticUserAuthenticated();
    return user.roles.findIndex(r => r.roleId === role) !== -1;
  }

  getUserAuthenticated(userAuthenticated$: Observable<UserAuthenticated<any, any>>): Observable<UserAuthenticated<any, any>> {

    let user = this.getStaticUserAuthenticated();

    if (user && user.user) {
        return Observable.create(observer => {
            observer.next(user);
            observer.complete();
        });
    }

    return userAuthenticated$
    .map((userAuth) => {
        user.user = userAuth;
        localStorage.setItem('userAuthenticated', JSON.stringify(user));
        return user;
    });
  }

  getRefreshUserAuthenticated(userAuthenticated$: Observable<UserAuthenticated<any, any>>): Observable<UserAuthenticated<any, any>> {

    let user = this.getStaticUserAuthenticated();

    return userAuthenticated$
    .map((userAuth) => {
        user.user = userAuth;
        localStorage.setItem('userAuthenticated', JSON.stringify(user));
        return user;
    });
  }

  isAuthenticated($refreshToken: Observable<Token>): Observable<boolean> {
    const token = this.getToken();

    if (this.isTokenExpired() && token != null) {
        return Observable.create((observer: Subscriber<any>) => {
          $refreshToken
            .catch(err => {
                observer.next(!this.isTokenExpired());
                observer.complete();
                //return this.handleError(err, 'Sua sessÃ£o expirou. Por favor, faÃ§a o processo de login novamente.');
                return this.handleError(err);
            })
            .subscribe(
                data => {
                    observer.next(!this.isTokenExpired());
                    observer.complete();
                }
            );
        });
    }

    return Observable.create(observer => {
        observer.next(!this.isTokenExpired());
        observer.complete();
    });
  }

  getToken(): Token {
    const user: UserAuthenticated<any, any> = this.getStaticUserAuthenticated();
    return user ? user.token : null;
  }

  getRefreshToken(): RefreshToken {
    const tokenAuth = this.getToken();
    const refreshToken = new RefreshToken();

    if (tokenAuth !== null && tokenAuth.refreshToken !== undefined)
      refreshToken.refreshToken = tokenAuth.refreshToken;

    return refreshToken;
  }

  login($token: Observable<Token>): Observable<UserAuthenticated<any, any>> {

    return $token.map((token: Token) => {
      let user = this.prepareUser(token);
      this.setUserAuthenticated(user);

      return user;
    })
    .catch((error) => {
      //return this.handleError(error, 'Email ou senha invÃ¡lidos.');
      return this.handleError(error);
    });
  }

  public getStaticUserAuthenticated(): UserAuthenticated<any, any> {
    return JSON.parse(localStorage.getItem('userAuthenticated'));
  }

  isTokenExpired(): boolean {
    const token = this.getToken();
    return token === null || token.accessToken === undefined || this.jwtHelper.isTokenExpired(token.accessToken);
  }

  refreshToken($refreshToken: Observable<Token>): Observable<Token> {
    return $refreshToken.map((token: Token) => {
        const user = this.getStaticUserAuthenticated();
        user.token = token;

        const newUser = this.prepareUser(token);

        localStorage.setItem('userAuthenticated', JSON.stringify(newUser));

        return token;
    });
  }

  setUserAuthenticated(userAuthenticated: UserAuthenticated<any, any>) {
      localStorage.setItem('userAuthenticated', JSON.stringify(userAuthenticated));
  }

  prepareUser(token: Token): UserAuthenticated<any, any> {

    const user = new UserAuthenticated();
    user.token = token;
    user.roles = [];

    const roles = this.jwtHelper.decodeToken(token.accessToken).role;

    if (Array.isArray(roles)) {
        roles.forEach(element => {
            const role = new Role<any>();
            role.roleId = element;
            user.roles.push(role);
        });

    } else if (roles != null && roles !== '') {
        const role = new Role<any>();
        role.roleId = roles;
        user.roles.push(role);
    }

    return user;
  }

  logout(): void {
    localStorage.removeItem('userAuthenticated');
  }

  logoutToAuthServer(oauth2Client: OAuth2Client): void {
    this.logout();
    window.location.href = this.buildUrlAuthServer(oauth2Client);
  }

  buildUrlAuthServer(oauth2Client: OAuth2Client): string {
    return '';
  }

  getEndpoint(): string {
    return 'token';
  }
  getEnvironment(): any {
      return undefined;
  }

  getClaim(claimType: string): string {
    const user = this.getStaticUserAuthenticated();
    return this.jwtHelper.decodeToken(user.token.accessToken)[claimType];
  }
}
