import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'arch-architecture',
  template: `
    <p>
      architecture works!
    </p>
  `,
  styles: []
})
export class ArchitectureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
